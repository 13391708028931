import React from 'react';
import { Link } from 'react-router-dom';
import './SCTestimonials.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';

import { RiDoubleQuotesR } from "react-icons/ri";
import blankprofile from '../assets/photos/blankprofile.png';
 


import SCFooter from '../SCFooter';


function SCTestimonials() {

return (
    <div className="testimonial-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="testimonial-hero">
            <div id="testimonial-hero-backdrop" >
                {/* <img src={hero}></img> */}
            </div>
            <div id="testimonial-hero-overlay">
                <h1>Testimonials</h1>
            </div>
        </section>
        <section id="testimonial-card-sections">
            <div className="testimonial-card">
                {/* <div className="testimonial-card-icon"> */}
                    {/* <RiDoubleQuotesR /> */}
                {/* </div> */}
                <div className="testimonial-card-photo"> 
                    {/* <img src={blankprofile}></img> */}
                    <RiDoubleQuotesR />
                </div>
                <div className="testimonial-card-text">
                    <p>
                        We signed a six-month agreement with Slaughter Comedy with an option to have Slaughter Comedy produce and promote increased business operations by establishing entertainment at Pop’s OMD Theater. Since signing an agreement with Bobby, 
                        we’ve witnessed a dramatic increase in foot traffic and the bottom line to business profits. 
                        Bobby now plans and produces comedy shows, he has the technical expertise to assist the company in establishing a business web presence on the internet and social media sites. 
                        Our overall business has seen 45% increase in sales and we continue to see new business. 
                        Bobby is versatile as a comedian who produces, 
                        promotes (to include show designs, tickets, flyers, posters) and arranges all aspects of show planning. 
                        Slaughter Comedy is your one-stop shop for small local business entertainment. 
                        He’ll design an affordable comedy show that fits your budget.
                    </p>
                    <br></br>
                    <p className="testimonial-bold-text">
                        Pop’s Soups, Subs, Sandwiches 
                        and Beverages/Official Music District (OMD) Theater 
                        (Jan 2017 – Present) Las Vegas, NV
                    </p>
                </div>
            </div>
            <div className="testimonial-card">
                {/* <div className="testimonial-card-icon"> */}
                   {/* <RiDoubleQuotesR /> */}
                {/* </div> */}
                <div className="testimonial-card-photo"> 
                    {/* <img src={blankprofile}></img> */}
                    <RiDoubleQuotesR />
                </div>
                <div className="testimonial-card-text">
                    <p>
                        Bobby was the MC for the Variety Show featuring the World famous InkSpots, Lou Ragland, Dean Lambus as “Sammy,” Phil Flowers and many more entertainers. 
                        Bobby not only hosted the show but performed his standup to entertain a diverse audience of old and young alike. 
                        Bobby is the consummate professional.
                    </p>
                    <br></br>
                    <p className="testimonial-bold-text">
                        Variety Show Celebrating Old School Legends 
                        /benefit for local Non profits Zappo's Theater

                    </p>
                </div>
            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>
    );
}

export default SCTestimonials;