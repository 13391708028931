import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './SCCurrentEvents.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';
import MSwSNS1 from '../assets/photos/fmradio.jpg';

import SCFooter from '../SCFooter';

function SCCurrentEvents() {

return (

    <div className="currentevents-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="currentevents-hero">
            <div id="currentevents-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div id="currentevents-hero-overlay">
                <h1>Current Events</h1>
            </div>
        </section>
        <section className="currentevents-section">
            <h3>HOT 7025FM</h3>
            <Link id="hot7025" to="https://hot7025fm.com/">
                <img src={MSwSNS1}></img>
            </Link>
            <Link id="currentevents-link" to="https://www.facebook.com/hot7025fm/">
                Every Wednesdays from 5:00 – 6:00 PM
            </Link>
            <p>Minority Report featuring Sanchez and Slaughter (S2)</p>
            <p>#MinorityReport #HOT7025FM #minoritybusiness #minorityreport #minoritymentalhealth</p>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>

);
}

export default SCCurrentEvents;