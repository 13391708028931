import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './SCLandingAbout.css';
import profilephoto from '../assets/photos/profilephoto.jpg';
import { GrInstagram, GrLinkedinOption, GrYoutube, GrFacebookOption, GrTwitter} from "react-icons/gr";

function SCLandingAbout() {

  const [isVisible, setIsVisible] = useState(false);  
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <div className="landingabout-wrapper">
        <div classname="about-sections photo-socials">
          <div id="about-photo">
            <img src={profilephoto}></img>
          </div>
          <div id="socials" ref={elementRef} className={`my-component ${isVisible ? 'enter-animationabout' : ''}`}>
            <Link id="instagram" to="https://www.instagram.com/bobbygslaughter/">< GrInstagram /></Link>
            <Link id="linkedin" to="https://www.linkedin.com/in/bobby-slaughter-785245146/"><GrLinkedinOption /></Link>
            <Link id="youtube" to="https://www.youtube.com/channel/UCKSIEI7hVoUMlqGxGwDecsA"><GrYoutube /></Link>
            <Link id="facebook" to="https://www.facebook.com/SlaughterComedy/"><GrFacebookOption /></Link>
            <Link id="twitter" to="https://x.com/BobbyGSlaughter"><GrTwitter /></Link>
          </div>
        </div>
        <div className="about-sections">
          <h1>
            About Us
          </h1>
          <p> 
            Slaughter Comedy is a veteran-operated Limited Liability Entertainment Company in Las Vegas,
            Nevada that produces & promotes tailorable comedy shows for small to medium-sized businesses and theaters.
          </p>
          <br></br>
          <p> 
            As CEO of Slaughter Comedy, I manage all aspects of event production,
            from talent search and selection of the comedians and entertainers to establishing top-notch talent rosters for venues.
            When I'm not producing shows, I’m working to improve my comedic craft around Las Vegas.
          </p>
          <Link id="button-to-bio" to="./AboutBobby">
            <button>Learn More</button>
          </Link>
        </div>
    </div>

);
}

export default SCLandingAbout;