import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './SCLandingServices.css';
import { HashLink } from 'react-router-hash-link';




function SCLandingServices() {

  const [isVisible, setIsVisible] = useState(false);  
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);



  return (
    <div className="landingservices-wrapper">
      <div id="services-info">
        <h2>Services</h2>
        <ol>
          <li> 
            <h3>Promotional Material</h3>
            <p>(Website, Flyers, Posters)</p>
          </li>
          <li> 
            <h3>Show Production</h3>
            <p>(Sound, Video, Photography)</p>
          </li>
          <li> 
            <h3>Host</h3>
            <p>Hosted shows involve professional videotaping of the event</p>
          </li>
        </ol>
        <HashLink smooth to="/#contact-form-hash" id="services-button"> {/* should take user to contact tab */}
          <button>See All Services</button>
        </HashLink>
      </div>
      <div ref={elementRef} className={`my-component ${isVisible ? 'enter-animationservices' : ''}`} id="services-img">
        {/* <img src={homelarge}></img> */}
      </div>
    </div>
);
}

export default SCLandingServices;