import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import './SCLandingMedia.css';
import ourMedia1 from '../assets/photos/homedraper.jpg';
import ourMedia2 from '../assets/photos/bobbyonstage.jpg';
import ourMedia3 from '../assets/photos/bobbyinairport.jpg';
import ourMedia4 from '../assets/photos/stagewithfolks.jpg';
import ourMedia5 from '../assets/photos/bobbywithrecord.jpg';
import ourMedia6 from '../assets/photos/acrew.jpg';
import ourMedia7 from '../assets/photos/purpleaudience.jpg';
import ourMedia8 from '../assets/photos/afam.jpg';

import ImageModal from './ImageModal';

import { FaPlusCircle } from "react-icons/fa";


function SCLandingMedia() {

    const images = [
    { src: ourMedia1, alt: 'SC-Image' },
    { src: ourMedia2, alt: 'SC-Image' },
    { src: ourMedia3, alt: 'SC-Image' },
    { src: ourMedia4, alt: 'SC-Image' },
    { src: ourMedia5, alt: 'SC-Image' },
    { src: ourMedia6, alt: 'SC-Image' },
    { src: ourMedia7, alt: 'SC-Image' },
    { src: ourMedia8, alt: 'SC-Image' }
    // Add more images as needed
  ];


  return (
    <div className="landingmedia-wrapper">
        <div className="media-text">
            <h2>Our Media</h2>
            <p> SEE MORE ABOUT US</p>
        </div>
        <div id="media-mini-gallery">
            
            <ImageModal />

        </div>
        <Link id="media-button" to='/Media'>
            <button>
                View More
            </button>
        </Link>

    </div>
);
}

export default SCLandingMedia;
