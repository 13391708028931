import React, { useState } from 'react';
import './ImageModal.css';
import { FaPlusCircle } from "react-icons/fa";
import ourMedia1 from '../assets/photos/homedraper.jpg';
import ourMedia2 from '../assets/photos/bobbyonstage.jpg';
import ourMedia3 from '../assets/photos/bobbyinairport.jpg';
import ourMedia4 from '../assets/photos/stagewithfolks.jpg';
import ourMedia5 from '../assets/photos/bobbywithrecord.jpg';
import ourMedia6 from '../assets/photos/acrew.jpg';
import ourMedia7 from '../assets/photos/purpleaudience.jpg';
import ourMedia8 from '../assets/photos/afam.jpg';

const ImageModal = ( ) => {

  const images = [
    { id: 1, imgsrc: ourMedia1, imgalt: 'SC-Image' },
    { id: 2, imgsrc: ourMedia2, imgalt: 'SC-Image' },
    { id: 3, imgsrc: ourMedia3, imgalt: 'SC-Image' },
    { id: 4, imgsrc: ourMedia4, imgalt: 'SC-Image' },
    { id: 5, imgsrc: ourMedia5, imgalt: 'SC-Image' },
    { id: 6, imgsrc: ourMedia6, imgalt: 'SC-Image' },
    { id: 7, imgsrc: ourMedia7, imgalt: 'SC-Image' },
    { id: 8, imgsrc: ourMedia8, imgalt: 'SC-Image' }
    // Add more images as needed
  ];

  
  const [modalVisible, setModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [hidden, unsetHidden] = useState(true);

  const toggle = () => {
    unsetHidden(!hidden);
};

  const openModal = (item, index) => {
    setCurrentImage(item.imgsrc);
    setModalVisible(true);
    toggle(unsetHidden);
  };

  const closeModal = () => {
    setModalVisible(false);
    setCurrentImage(null);
  };

  return (
    <>
      {images.map((item, index) => (
        <div className="container">
          <img className="image"
            key={index}
            src={item.imgsrc}
            alt={item.imgalt}
            
            style={{ cursor: 'pointer'}}
          />

          <div className="middle" onClick={() => openModal(item, index)}>
              <div className="text" onClick={() => openModal(item, index)} ><FaPlusCircle onClick={() => openModal(item, index)}/></div>
          </div>
        </div>
      ))}

      {modalVisible && (
        <div className={hidden ? "modal-hidden" : "modal-show"}>
            <span className="close" onClick={closeModal}>&times;</span>
            <img className="modal-content" src={currentImage}></img>
        </div>
      )}
    </>
  );
};

export default ImageModal;