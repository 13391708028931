import React from 'react';
import { Link } from 'react-router-dom';
import './SCLandingTestimonial.css';
import { RiDoubleQuotesL } from "react-icons/ri";
import hero from '../assets/photos/hero.jpg';

function SCLandingTestimonials() {
  return (
    <div className="landingtestimonial-wrapper">
        <div className="testimonials-div" id="double-quotes-bg">
            <RiDoubleQuotesL />
            {/* <img id = "hero-backdrop1" src={hero}></img> */}
        </div>
        <div className="testimonials-div" id="testimonial-text">
            <h2>Everthing in One Place</h2>
            <h4>Convenient Access to Everything You Need</h4>
            <p>
                "We signed a six-month agreement with Slaughter Comedy with an option to have Slaughter Comedy produce and promote increased business operations by establishing entertainment at Pop’s OMD Theater.
                Since signing an agreement with Bobby, we’ve witnessed a dramatic increase in foot traffic and the bottom line to business profits. Bobby now plans and produces comedy shows,
                he has the technical expertise to assist the company in establishing a business web presence on the internet and social media sites.
                Our overall business has seen a 45% increase in sales and we continue to see new business."
            </p>
            <Link id="testimonial-button" to='/Testimonials'>
                <button>See Testimonials</button>
            </Link>
        </div>
    </div>

);
}

export default SCLandingTestimonials;