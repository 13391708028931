import React from 'react';
import { Link } from 'react-router-dom';
import './SCLandingEvents.css';
import scHomeVideo from '../assets/photos/scHomeVideo.mp4';


function SCLandingEvents() {
  return (
    <div className="landingevents-wrapper">
      <div id="events-video">
        <video src={scHomeVideo} controls></video>
      </div>
      <div id="events-info">
        <h2>Previous Events</h2>
        <ul>
          <li> Nellis AFB Comedy Night </li>
          <li> Slaughter Comedy Showdown </li>
          <li> Birthday Bash </li>
          <li> Summer Comedy Jam </li>
          <li> "Laughter Uncorked" Comedy Special </li>
          <li> Open Mic Nights </li>
        </ul>
        <Link id="button-to-events" to='/PastEvents'>
          <button>See All Events</button>
        </Link>
      </div>
    </div>
);
}

export default SCLandingEvents;