import React from 'react';
import { Link } from 'react-router-dom';
import './SCResources.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';

import SCFooter from '../SCFooter';

function SCResources() {

return (
    <div className="resources-wrapper">
        <section className="resources-wrapper">
            <SCNavbar />
        </section>
        <section id="resources-hero">
            <div className="resources-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div className="resources-hero-overlay">
                <h1>Resources</h1>
            </div>
        </section>
        <section className="resources-readme">
            <h1 className="resources-readme-h1">I. Investing Resources</h1>
            <div>
                <h2 className="resources-readme-h2"> <span className="bullet-letter"> a) </span> Nerd Wallet</h2>
                <p className="resources-readme-text">Looking for objective advice, expert info, and helpful tools to answer your money questions? Turn to the Nerds.</p>
                <Link className="resources-readme-link-highlight" to="https://www.nerdwallet.com/">
                    NerdWallet: Make all the right money moves
                </Link>
            </div>
            <div>
                <Link to="https://www.google.com/finance/">
                    <h2 className="resources-readme-h2"> <span className="bullet-letter"> b) </span> Google Finance – Stock Market Prices, Real-time Quotes & Business News</h2>
                </Link>
            </div>
            <div>
                <Link to="https://finance.yahoo.com/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuc2xhdWdodGVyY29tZWR5LmNvbS8&guce_referrer_sig=AQAAACL7Ea9ZgggE7gYX79EGuJb3fCBFsimxTQBbulZXndjEDGAbg8_OSKw2odnwvEbKP2wUJqIcpCsmZWKs0xIo7qd-9M9K4_A6vPGKsJ6Lh86z1r-I2Tlj98u8SKtV4ZC1RfT2BlVLXJoONqyajITInM251lEy6ebzr-Zm3xrypRGo">
                    <h2 className="resources-readme-h2"> <span className="bullet-letter"> c) </span> Yahoo Finance – Stock Market Live, Quotes, Business & Finance News</h2>
                </Link>
            </div>
            <div>
                <h2 className="resources-readme-h2"> <span className="bullet-letter"> d) </span> My Favorites (great websites with investor learning tools)</h2>
            </div>
            <div>
                <div>
                    <h3 className="resources-readme-h3">Fidelity</h3>
                    <Link className="resources-readme-link-highlight" to="https://www.fidelity.com/">
                        <p>
                            Fidelity Investments – Retirement Plans, Investing, Brokerage, Wealth Management,
                            Financial Planning and Advice, Online Trading.
                        </p>
                    </Link>
                </div>
                <div>
                    <h3 className="resources-readme-h3"> TD Ameritrade</h3>
                    <Link className="resources-readme-link-highlight" to="https://www.schwab.com/td-ameritrade">
                        <p>Online Stock Trading, Investing, Brokerage | TD Ameritrade</p>
                    </Link>
                </div>
                <div>
                    <h3 className="resources-readme-h3">Market Watch</h3>
                    <p className="resources-readme-text">
                        MarketWatch is a website that provides financial information, business news, analysis, and stock market data.
                        Along with The Wall Street Journal and Barron’s, it is a subsidiary of Dow Jones & Company, a property of News Corp.
                    </p>
                    <Link className="resources-readme-link-highlight" to="https://www.schwab.com/td-ameritrade">
                        <p>MarketWatch: Stock Market News – Financial News – MarketWatch</p>
                    </Link>
                </div>
                <div>
                    <h3 className="resources-readme-h3">Candlestick Analysis</h3>
                    <Link className="resources-readme-link-highlight" to="https://www.candlesticker.com/Default.aspx?lang=en">
                        <p>Candlestick Analysis, by Americanbulls.com (candlesticker.com)</p>
                    </Link>
                </div>
            </div>
        </section>
        <section className="resources-readme">
            <h1 className="resources-readme-h1">II. Technology Websites</h1>
            <p className="resources-readme-text">Open Source Software (If you can’t afford a specific software, visit these sites and search for an alternative at:</p>
            <div>
                <Link className="resources-readme-link-highlight" to="https://portableapps.com/">
                    <h2 className="resources-readme-h2"> <span className="bullet-letter"> a) </span> PortableApp.com</h2>
                </Link>
            </div>
            <div>
                <Link className="resources-readme-link-highlight" to="https://sourceforge.net/">
                    <h2 className="resources-readme-h2"> <span className="bullet-letter"> b) </span> SourceForge</h2>
                </Link>
            </div>
            <div>
                <h2 className="resources-readme-h2"> <span className="bullet-letter"> c) </span> Alternative Graphics Editor</h2>
                <Link className="resources-readme-link-highlight" to="https://www.gimp.org/">
                    GIMP – GNU Image Manipulation Program
                </Link>
                
            </div>
            <div>
                <h2 className="resources-readme-h2"> <span className="bullet-letter"> d) </span> Alternative to Microsoft Office</h2>
                <Link className="resources-readme-link-highlight" to="https://www.openoffice.org/">
                    <p>Apache OpenOffice</p>
                </Link>
                <Link className="resources-readme-link-highlight" to="https://www.libreoffice.org/">
                    LibreOffice
                </Link>
            </div>
            <div>
                <h2 className="resources-readme-h2"> <span className="bullet-letter"> e) </span> Linux Alternative to Windors</h2>
                <Link className="resources-readme-link-highlight" to="https://ubuntu.com/download/desktop">
                    <p>Ubuntu</p>
                </Link>
            </div>
        </section>
        <section className="resources-readme">
            <h1 className="resources-readme-h1">III. Miscellaneous</h1>
            <div>
                <h2 className="resources-readme-h2"> a) References/Library Online</h2>
                <Link className="resources-readme-link-highlight" to="https://archive.org/details/guggenheimlibrary">
                    <p>Guggenheim Library Collection Page : Free Texts : Free Download, Borrow and Streaming : Internet Archive</p>
                </Link>
            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>
    );
}

export default SCResources;