import React from 'react';
import { Link } from 'react-router-dom';
import './SCPastEvents.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';

import OMG from '../assets/photos/OMGpizza.jpg';
import TTOS from '../assets/photos/ThisorThat.png';
import LUPD from '../assets/photos/laughteruncorked.png';

import NAV from '../assets/photos/notavictim.png';
import LVSCS from '../assets/photos/lasvegas.png';

import TBD1 from '../assets/photos/tbd1.jpg';
import THUNOV from '../assets/photos/30THUNOV.jpg';
import SATDEC from '../assets/photos/SATDEC.jpg';
import SUNJAN from '../assets/photos/SUNJAN.jpg';



import SCFooter from '../SCFooter';

function SCPastEvents() {

return (
    <div className="pastevents-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="pastevents-hero">
            <div id="pastevents-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div id="pastevents-hero-overlay">
                <h1>Past Events</h1>
            </div>
        </section>
        <section className="pastevents-section">
            <div className="pastevents-section-single" >
                <h3 className="pastevents-section-groupe-h3">OMG Pizza Show</h3>
                <img src={OMG}></img>
            </div>
            <div className="pastevents-section-grouped">
                <div>
                    <h3 className="pastevents-section-groupe-h3">THIS, THAT, THE OTHER & THEN SOME</h3>
                    <img src={TTOS}></img>
                </div>
                <div>
                    <h3 className="pastevents-section-groupe-h3">LAUGHTER UNCORKED PART DEUX</h3>
                    <img src={LUPD}></img>
                </div>
            </div>
            <div className="pastevents-section-grouped white">
                <div>
                    <h3 className="pastevents-section-groupe-h3">NOT A VICTIM</h3>
                    <img src={NAV}></img>
                </div>
                <div>
                    <h3 className="pastevents-section-groupe-h3">LAS VEGAS SMOKIN’ COMEDY SHOWCASE</h3>
                    <img src={LVSCS}></img>
                </div>
            </div>
            <div className="pastevents-section-grouped">
                <div className="pastevents-section-grouped-read">
                    <h1 className="pastevents-section-groupe-h1">TBD</h1>
                    <h3 className="pastevents-section-groupe-h3">THIS, THAT, THE OTHER AND THEN SOME</h3>
                    <p>Check SlaughterComedy FB for dates</p>
                    <p>953 E Sahara Ave, 953 E Sahara Ave, Las Vegas, NV 89104, USA</p>
                    <p>
                        Variety Show featuring comedy, singing, magicians, ventriloquist and much more
                    </p>
                    <Link to="https://www.facebook.com/SlaughterComedy/">
                        <button> Register Now</button>
                    </Link>
                </div>
                <div className="pastevents-section-grouped-img">
                    <img src={TTOS}></img>
                </div>
            </div>
            <div className="pastevents-section-grouped white">
                <div className="pastevents-section-grouped-img">
                    <img src={TBD1}></img>
                </div>
                <div className="pastevents-section-grouped-read">
                    <h1 className="pastevents-section-groupe-h1">TBD</h1>
                    <h3 className="pastevents-section-groupe-h3">STANDUP COMDEY WORKGROUP</h3>
                    <p>Time TBD</p>
                    <p>953 E. SAHARA AVE, LAS VEGAS, NV 89104</p>
                    <p>
                        Join me and a comedy work group for practice sessions in a environment designed to assist you in performing.
                        Open mics are great, but learn from other like-minded individuals dedicated to honing their craft. Coffee, snacks and fun.</p>
                    <Link to="https://www.facebook.com/SlaughterComedy/">
                        <button> Register Now</button>
                    </Link>
                </div>
            </div>
            <div className="pastevents-section-grouped">
                <div className="pastevents-section-grouped-read">
                    <h1 className="pastevents-section-groupe-h1">30 THU NOV</h1>
                    <h3 className="pastevents-section-groupe-h3">QUEEN G LIVE EXPERIENCE PILOT TAPING</h3>
                    <p>Nov 30, 2017 at 7:00pm – 11:30pm MST</p>
                    <p>953 E Sahara Ave, 953 E Sahara Ave, Las Vegas, NV 89104, USA</p>
                    <p>Come out and support this awesome live show</p>
                    <Link to="https://www.facebook.com/SlaughterComedy/">
                        <button> Register Now</button>
                    </Link>
                </div>
                <div className="pastevents-section-grouped-img">
                    <img src={THUNOV}></img>
                </div>
            </div>
            <div className="pastevents-section-grouped white">
                <div className="pastevents-section-grouped-img">
                    <img src={SATDEC}></img>
                </div>
                <div className="pastevents-section-grouped-read">
                    <h1 className="pastevents-section-groupe-h1">30 SAT DEC</h1>
                    <h3 className="pastevents-section-groupe-h3">NETWORKING NIGHT: COMDEY, PHOTOGRAPHY & ART</h3>
                    <p>Dec 30, 2017 at 8:00pm – 11:30pm PST</p>
                    <p>953 E Sahara Ave, Las Vegas, NV 89104, USA</p>
                    <p>
                        Come out and join us on 12/30/17 for an evening of comedy, performances, and networking.
                        Bring in the new year with new connections. This is a relationship builder.
                        Sit down for a conversation, order something from the menu, have a drink or two, and enjoy the lineup (see hyperlinks below)
                    </p>
                    <Link to="https://www.facebook.com/SlaughterComedy/">
                        <button> Register Now</button>
                    </Link>
                </div>
            </div>
            <div className="pastevents-section-grouped">
                <div className="pastevents-section-grouped-read">
                    <h1 className="pastevents-section-groupe-h1">21 SUN JAN</h1>
                    <h3 className="pastevents-section-groupe-h3">MASON PRYOR AT OMD THEATHER</h3>
                    <p>Jan 21 – 22, 2018 at 8:00pm – 8:00pm PST</p>
                    <p>953 E Sahara Ave, 953 E Sahara Ave, Las Vegas, NV 89104, USA</p>
                    <p>Y2K Associates and Slaughter Comedy are bringing you Mason Pryor to OMD with a great cast of local comedians.</p>
                    <Link to="https://www.facebook.com/SlaughterComedy/">
                        <button> Register Now</button>
                    </Link>
                </div>
                <div className="pastevents-section-grouped-img">
                    <img src={SUNJAN}></img>
                </div>
            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>
    );
}

export default SCPastEvents;