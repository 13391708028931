import React from 'react';
import { Link } from 'react-router-dom';
import './SCLandingHero.css';
import hero from '../assets/photos/hero.jpg';
import { FaRegCircle } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import SCCarousel from '../Carousel/SCCarousel';


function SCLandingHero() {
  return (
    <div className="hero-wrapper">
        <div id="carousel">
            <SCCarousel />
        </div>
    </div>

);
}

export default SCLandingHero;