import './Gallery.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import React, { useState, useRef, useEffect } from 'react';

import { MdArrowBackIos } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import { BsXLg } from 'react-icons/bs';

import img1 from '../assets/photos/purpleaudience.jpg';
import img2 from '../assets/photos/brightlights.jpg';
import img3 from '../assets/photos/comedyshowdown.jpg';
import img4 from '../assets/photos/bobbywithrecord.jpg';
import img5 from '../assets/photos/acrew.jpg';
import img6 from '../assets/photos/tinaturner.jpg';
import img7 from '../assets/photos/kidz.jpg';
import img8 from '../assets/photos/afam.jpg';
import img9 from '../assets/photos/purplebobby.jpg';
import img10 from '../assets/photos/homedraper.jpg';
import img11 from '../assets/photos/poseforthecam.jpg';
import img12 from '../assets/photos/happybirthdaybobby.jpg';
import img13 from '../assets/photos/stagewithfolks.jpg';
import img14 from '../assets/photos/comedynightpops.jpg';
import img15 from '../assets/photos/ThisorThat.png';
import img16 from '../assets/photos/bobbyinairport.jpg';
import img17 from '../assets/photos/bobbyonstage.jpg';
import img18 from '../assets/photos/homesquadimages.jpg';
import img19 from '../assets/photos/comedysummer.jpg';
import img20 from '../assets/photos/bobby1.jpg';
import img21 from '../assets/photos/laughteruncorked.png';
import img22 from '../assets/photos/SATDEC.jpg';
import img23 from '../assets/photos/tbd1.jpg';
import img24 from '../assets/photos/Y2K.jpg';
import img25 from '../assets/photos/apollo.jpg';

function Gallery() {

const galleryimages = [

    {id: 1, imgsrc: img1},
    {id: 2, imgsrc: img2},
    {id: 3, imgsrc: img3},
    {id: 4, imgsrc: img4},
    {id: 5, imgsrc: img5},
    {id: 6, imgsrc: img6},
    {id: 7, imgsrc: img7},
    {id: 8, imgsrc: img8},
    {id: 9, imgsrc: img9},
    {id: 10, imgsrc: img10},
    {id: 11, imgsrc: img11},
    {id: 12, imgsrc: img12},
    {id: 13, imgsrc: img13},
    {id: 14, imgsrc: img14},
    {id: 15, imgsrc: img15},
    {id: 16, imgsrc: img16},
    {id: 17, imgsrc: img17},
    {id: 18, imgsrc: img18},
    {id: 19, imgsrc: img19},
    {id: 20, imgsrc: img20},
    {id: 21, imgsrc: img21},
    {id: 22, imgsrc: img22},
    {id: 23, imgsrc: img23},
    {id: 24, imgsrc: img24},
    {id: 25, imgsrc: img25}
];

const [hide, show] = useState(false);

const [clickedImg, setClickedImg] = useState(null);
const [currentIndexx, setCurrentIndexx] = useState(null);
const [hidden, unsetHidden] = useState(true);
const [scroll, noScroll] = useState(true);

const toggle = () => {
    unsetHidden(!hidden);
};

const scrollToggle = () => {
    noScroll(!scroll);
};

useEffect (() => {
    if (scroll) {
        document.body.style.overflow = "scroll";
    } else {
        document.body.style.overflow = "hidden"
    }

}, [scroll]);

const handleClick = (item, index) => {
    setCurrentIndexx(index);
    setClickedImg(item.imgsrc);
    toggle(unsetHidden);
    scrollToggle(noScroll);
};

const secondHandleClick = () => {
    toggle(unsetHidden);
    scrollToggle(scroll);
}

const timerRef = useRef(null);

const goToNext = () => {
    const totalLength = galleryimages.length;
    if(currentIndexx + 1 >= totalLength) {
        setCurrentIndexx(0);
        const newImg = galleryimages[0].imgsrc;
        setClickedImg(newImg);
        return;
    }
    

    const newIndexx = currentIndexx + 1;
    const newImg = galleryimages.filter((item) => {
        return galleryimages.indexOf(item) === newIndexx;
    });
    const newItem = newImg[0].imgsrc;
    setClickedImg(newItem);
    setCurrentIndexx(newIndexx);
    };

const goToPrevious = () => {
    const totalLength = galleryimages.length;
    if(currentIndexx === 0) {
        setCurrentIndexx(totalLength - 1);
        const newImg = galleryimages[totalLength - 1].imgsrc;
        setClickedImg(newImg);
        return;
    }
    

    const newIndexx = currentIndexx - 1;
    const newImg = galleryimages.filter((item) => {
        return galleryimages.indexOf(item) === newIndexx;
    });
    const newItem = newImg[0].imgsrc;
    setClickedImg(newItem);
    setCurrentIndexx(newIndexx);
    };

return (

<div>

    <div className="galleryWrapper noScroll">

        <section>

        </section>

        {/*......images section */}
        <section classname= "gallery">
            <div className="galleryGridContainer">

                {galleryimages.map((item, index) => {
                    return(
                        <div 
                            key={index}
                            className="galleryGridItem"
                        >
                                <img
                                    className = "style"
                                    src={item.imgsrc} 
                                    onClick={()=>handleClick(item, index)}
                                    fetchpriority = "high"
                                    loading="lazy"
                                    // style={{width: '233px', height: '248px', objectFit: 'cover'}}
                                />
                        </div>
                    )
                    }
                    )
                };

                {
                    {clickedImg} && (
                        <div 
                            className={hidden ? "hideSlider" : "gallerySliderContainer"}
                        >
                            <div id="exitButton">
                                <BsXLg 
                                onClick={()=>secondHandleClick()}
                                />
                            </div>
                                <MdArrowBackIos class="leftarrow" onClick={goToPrevious}/>
                                <div className="gallerySlideStyles" parentWidth = {500}>
                                    <img className="gallerySliderImage" src={clickedImg}></img>
                                </div>
                                <MdArrowForwardIos class="rightarrow" onClick={goToNext}/>
                        </div>
                    )
                }

            </div>
        </section>
    </div>
</div>

);
}

export default Gallery;