import React from 'react';
import { Link } from 'react-router-dom';
import './SCFooter.css';

import SCScrollToTop from './ScrollToTop/SCScrollToTop';

function SCFooter() {
  return (

    <div className="footer-wrapper">
        <div id="scroll-to-top">
            <SCScrollToTop />
        </div>
        <footer id="footer-text">
            <h4> ©  Slaughter Comedy 2024. Built by: Beyond Sight Solutions</h4>
        </footer>
    </div>

);
}

export default SCFooter;