import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './SCContactForm.css';
import emailjs from '@emailjs/browser';


function SCContactForm() {

  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ygkfx7s',
      'template_d66swgn',
      form.current,
      'zFLZH29bRNDazrYni'
      ).then((result) => {
        setMessageSent(true);  
        console.log(result.text);
        console.log("message sent");
      }, (error) => {
          console.log(error.text);
          console.log("message in error");
      });
  };

  return (
    <div className="contactform-wrapper">
      <div id="contact-backdrop">
        {/* <img src={hero}></img> */}
      </div>
      <div id="contact-form">
        <h1>Contact Us</h1>
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <input
              type="text"
              name="user_name"
              id = "name"
              placeholder="Name*"
              required>
            </input>
          </div>
          <div>
            <input 
              type="text"
              name="user_email"
              id = "email"
              placeholder="Email Address*"
              required>
            </input>
          </div>
          <div>
            <input 
              type="text"
              name="subject"
              id = "subject"
              placeholder="Subject"
              required>
            </input>
          </div>
          <div>
            <textarea 
              type="text"
              id = "message"
              name="message"
              placeholder="Message"
            />
          </div>
          <div className="button-container">
              <button 
                type="submit"
                value="Send"
                id="contact-button"
                > 
                  SUBMIT
              </button>
          </div>
          {messageSent && <p>Thanks for submitting!</p>}
        </form>
      </div>
    </div>
);
}

export default SCContactForm;