import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import showtime from '../assets/photos/showtime.mp4';
import homevideo from '../assets/photos/scHomeVideo.mp4';
import home1 from '../assets/photos/home1.jpg';
import microphone from '../assets/photos/microphone.jpg';
import hero from '../assets/photos/hero.jpg';

import './SCCarousel.css';


function SCCarousel() {

  const [isVisible, setIsVisible] = useState(false);  
  const elementRef = useRef(null);

  useEffect(() => {
  const observer = new IntersectionObserver(
      ([entry]) => {
      setIsVisible(entry.isIntersecting);
      },
      { threshold: 0 } // Trigger when 10% of the element is visible
  );

  if (elementRef.current) {
      observer.observe(elementRef.current);
  }

  return () => {
      if (elementRef.current) {
      observer.unobserve(elementRef.current);
      }
  };
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const items = [
    { id: 1, header1: 'Laughter`s In the name', header2: 'To follow Slaughter Comedy gigs and more via mobile: Type keyword <laughster> and send to 85100', type: 'image', src: home1 },
    { id: 2, header1: 'Laughter`s In the name', header2: 'To follow Slaughter Comedy gigs and more via mobile: Type keyword <laughster> and send to 85100', type: 'video', src: showtime },
    { id: 3, header1: 'Laughter`s In the name', header2: 'To follow Slaughter Comedy gigs and more via mobile: Type keyword <laughster> and send to 85100', type: 'image', src: microphone },
    { id: 4, header1: 'Laughter`s In the name', header2: 'To follow Slaughter Comedy gigs and more via mobile: Type keyword <laughster> and send to 85100', type: 'video', src: homevideo },
    { id: 5, header1: 'Laughter`s In the name', header2: 'To follow Slaughter Comedy gigs and more via mobile: Type keyword <laughster> and send to 85100', type: 'image', src: home1 },

    // Add more items here
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
      }
    }, 8000); // Change slide every 8 seconds
    return () => clearInterval(interval);
  }, [isPaused, items.length]);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handleIndicatorClick = (index) => {
    setActiveIndex(index);
  };

  const handlePause = () => {
    setIsPaused(!isPaused);
  };

  return (
    <div className="carousel-wrapper">
      {items.map((item, index) => (
        <div id="carousel" 
            className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={item.id}>
            <div id="carousel-div-a">
                {item.type === 'image' ? <img  src={item.src} alt={item.header1} /> : <video src={item.src} autoPlay loop muted />}
            </div>
            <div ref={elementRef} className={`my-component ${isVisible ? 'enter-animationcar' : ''}`} id="carousel-div-b">
                <h1>{item.header1}</h1>
                <h2>{item.header2}</h2>
                <Link to='/AboutBobby'>
                  <button className="carousel-button" onClick={handlePause}>LEARN MORE</button>
                </Link>
                
            </div>
        </div>
      ))}
      <div className="carousel-navigation">
        {/* <button onClick={handlePrev}>&lt;</button> */}
        {/* <button onClick={handleNext}>&gt;</button> */}
      </div>
      <div className="carousel-indicators">
        {items.map((_, index) => (
          <div
            key={index}
            className={`carousel-indicator ${index === activeIndex ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );


}

export default SCCarousel;
