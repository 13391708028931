import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './SCMedia.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';
import scMediaShow from '../assets/photos/scMediaShow.mp4';

import Gallery from '../Media/Gallery';

import SCFooter from '../SCFooter';

function SCMedia() {

  const [isVisible, setIsVisible] = useState(false);  
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

return (
    <div className="media-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="media-hero">
            <div id="media-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div id="media-hero-overlay">
                <h1>Media</h1>
            </div>
        </section>

        <section className="media-gallery">
            <Gallery />
        </section>
        <section id="media-video">
            <video src={scMediaShow} type="video/mp4" controls ref={elementRef} className={`my-component ${isVisible ? 'media-video-item enter-animationx' : 'media-video-item'}`} >
                {/* <source src={scMediaShow} type="video/mp4" controls></source> */}
            </video>
            <div>
                <iframe className="media-video-item" src="https://www.youtube.com/embed/NYezhDPW35o?si=dQQfJ4qOx3GIlqSV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <iframe className="media-video-item" src="https://www.youtube.com/embed/yD1G8mi3I0w?si=LMY9yyH5MFl1S_D5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>
    );
}

export default SCMedia;