import React from 'react';
import { Link } from 'react-router-dom';
import './SCLandingIntermission.css';
import bluemic from '../assets/photos/bluemic.jpg';

function SCLandingIntermission() {
  return (
    <div className="intermission-wrapper">
        <div className="intermission-div">
          {/* <img id="intermission-img" src={hero}></img> */}
        </div>
        <div className="intermission-text">
          <h2>Bringing Affordable Entertainment to Las Vegas</h2>
          <p>“Slaughter Comedy is out to prove Las Vegas has talented people off the strip, one show at a time”</p>
        </div>
    </div>

);
}

export default SCLandingIntermission;