import React from 'react';
import { Link } from 'react-router-dom';
import './SCComedyLinks.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';

import SCFooter from '../SCFooter';


function SCComedyLinks() {



return (
    <div className="LVLCL-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="LVLCL-hero">
            <div id="LVLCL-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div id="LVLCL-hero-overlay">
                <h1>Las Vegas Local Comedy Links</h1>
            </div>
        </section>
        <section className="LVLCL-readme">
            <div className="LVLCL-readme-div">
                <p className="LVLCL-readme-text">
                    The definitive list of Las Vegas comedy open mics is kept on a facebook document
                    and also embedded in the posts below.vvIt also lives in the pinned post at the top of two facebook groups:
                </p>
                <br></br>
                <div className="LVLCL-readme-div">
                    <p className="LVLCL-readme-text">
                        <span className="LVLCL-readme-a">
                            <Link to="https://www.facebook.com/groups/vegaslocalcomedy/">
                                Las Vegas Comedy Shows 
                            </Link>
                        </span>
                        only allows posts about local comedy shows.
                        We keep the post volume to a minimum so fans can know what’s going on
                        without scrolling through a bunch of crap or drowning in notifications.
                    </p>
                    <br></br>
                </div>
                <div className="LVLCL-readme-div">

                    <p className="LVLCL-readme-text">
                        <span className="LVLCL-readme-a">
                            <Link to="https://www.facebook.com/groups/vegascomediansonly/">
                                Las Vegas Comedians (Discussion & Open Mics)
                            </Link>
                        </span>
                        is for comedians/producers that live in Vegas, got started there, or come through often.
                        It allows discussion and posting stage time offers (like open mics).
                    </p>
                </div>
                <p className="LVLCL-readme-text">You Want to Do Standup in Vegas? Really? Then click here!</p>
                <br></br>
                <p className="LVLCL-readme-text">Las Vegas Local Comedy Scene…check it out on Facebook also!</p>
                <br></br>
            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>
    );
}

export default SCComedyLinks;