import React from 'react';
import { Link } from 'react-router-dom';
import './SCMakingSensewSNS.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';
import MSwSNS1 from '../assets/photos/fmradio.jpg';
import MSwSNS2 from '../assets/photos/sebastian.jpg';
import MSwSNS3 from '../assets/photos/SCdogtags.jpg';

import SCFooter from '../SCFooter';

function SCMakingSensewSNS() {

return (
    <div className="MSwSNS-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="MSwSNS-hero">
            <div id="MSwSNS-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div id="MSwSNS-hero-overlay">
                <h1>Making Sense with Sanchez N Slaughter</h1>
            </div>
        </section>
        <section className="MSwSNS-sections">
            <div className="MSwSNS-grouped">
                <div>
                    <img src={MSwSNS1}></img>
                </div>
                <div className="MSwSNS-readme">
                    <h1> Making Sense with Sanchez N Slaughter</h1>
                    <p> 
                        Making Sense with Sanchez N Slaughter Facebook represents the collaborative efforts of Sanchez and Slaughter
                        and serves as a source to access our broadcast content as well as a place to interact with us.
                        We hope you enjoy the shared insights!
                    </p>
                    <Link className="MSwSNS-button-wrapper" to="https://makingsensewithsancheznslaughter.buzzsprout.com/">
                        <button>Listen to Our Podcast</button>
                    </Link>
                    <Link className="MSwSNS-button-wrapper" to="https://www.youtube.com/channel/UCf9isuBRlwmxjrpAWBhXrJg">
                        <button>Follow Our Youtube Channel</button>
                    </Link>
                </div>
            </div>
            <div className="MSwSNS-grouped">
                <div className="MSwSNS-readme">
                    <h1>Sebastian Sanchez</h1>
                    <p> 
                        Sebastian is a Chilean immigrant, a world traveler and public servant who was raised in Miami, but currently resides in Las Vegas.
                        As a former illegal immigrant, for many years his life revolved around uncertainty. Despite limited opportunities,
                        Sebastian was able to earn a full scholarship to Florida State University
                        where he earned a Bachelor of Science in Finance and a minor in Italian in 2008.
                    </p>
                    <p>
                        Sebastian began his career working for a major for-profit university, 
                        where he became aware of the exploitation of vulnerable Americans within the educational system. 
                        Despite a promising career, he resigned from his job to pursue his passion for teaching minorities in neglected communities. 
                        In 2012, Sebastian earned a Master of Arts in English from National University which allowed him to transition into teaching college courses at Miami-Dade College and Strayer University.
                        He currently works as a high school teacher and volunteers his time as an EMT Firefighter.
                    </p>
                    <p>
                        In 2020, Sebastian decided to combine his passion for service with his comedic persona and created The Minority Report.
                        In collaboration with Bobby Slaughter, Sebastian aims to educate and inspire disenfranchised Americans from all economic backgrounds.
                        When he is not recording, teaching, or working in an ambulance,
                        you may be able to catch his stand up at local open mics and comedy clubs in Las Vegas.
                    </p>
                </div>
                <div>
                    <img id="sanchez" src={MSwSNS2}></img>
                </div>
            </div>
            <div id="MSwSNS-announcements">
                <h3>Announcement</h3>
                <h2> May 22, 2020</h2>
                <p>
                    Follow us on Facebook and share your insight on Making Sense with Sanchez & Slaughter.
                </p>
                <Link id="sanchez-button" to="https://www.facebook.com/people/Making-Sense-with-Sanchez-Slaughter/100064042992396/">
                    <button> Follow Making Sense with Sanchez & Slaughter</button>
                </Link>
                <img></img>
            </div>
            <div className="MSwSNS-grouped">
                <div>
                    <img id="dogtag" src={MSwSNS3}></img>
                </div>
                <div className="MSwSNS-readme">
                    <h1>Slaughter Comedy</h1>
                    <p>
                        A Vetrepreneur, Comedian and businessperson dedicated to promoting local comedy and comics in Las Vegas to bring some laughter to all communities.
                        Slaughter Comedy is a member of the Las Vegas Metro Chamber of Commerce and licensed as a Limited Liability Company in the State of Nevada and licensed as a promoter in Clark Country.
                        Additionally, Slaughter Comedy maintains a licensed membership with the American Society of Composers, Authors, and Publishers.
                    </p>
                    <Link className="MSwSNS-button-wrapper" to="https://www.facebook.com/SlaughterComedy/">
                        <button> Follow Slaughter Comedy</button>
                    </Link>
                </div>
            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>
    );
}

export default SCMakingSensewSNS;