import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './SCLandingClients.css';
import homesection2 from '../assets/photos/homesection2.jpg';
import homesquad from '../assets/photos/homesquadimages.jpg';

function SCLandingClients() {

  const [isVisible, setIsVisible] = useState(false);  
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);


  return (
    <div className="landingclients-wrapper">
      <div className="clients-div" id="clients-img1">
        {/* <img src={homesection2}></img>  */}
        {/* image is added to the css background image property instead */}
      </div>
      <div className="clients-div" id="clients-text">
        <h2>Clients</h2>
        <p>Our clients include both comedians and small to medium-sized businesses. We work with small businesses to promote.</p>
      </div>
      <div className="clients-div" id="clients-img2">
        <img ref={elementRef} className={`my-component ${isVisible ? 'enter-animationhome' : ''}`} src={homesquad}></img>
      </div>
    </div>

);
}

export default SCLandingClients;