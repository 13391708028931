import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './SCNavbar.css';
import logo from './assets/photos/logo.png';
import {FaPhone} from 'react-icons/fa6';
import {IoMdMail} from 'react-icons/io';
import {RxHamburgerMenu} from 'react-icons/rx';
import {GrClose} from 'react-icons/gr';
import {IoIosArrowUp} from 'react-icons/io';
import {IoIosArrowDown} from 'react-icons/io';

function SCNavbar() {

    const [isOpen, setIsOpen] = useState(false);

    const openNav = () => {
        setIsOpen(true);
    };

    const closeNav = () => {
        setIsOpen(false);
    };

  const [activeDropdowns, setActiveDropdowns] = useState({});

  useEffect(() => {
    const dropdownBtns = document.getElementsByClassName("dropdown-btn");
    const newActiveDropdowns = {};

    Array.from(dropdownBtns).forEach((btn, index) => {
      newActiveDropdowns[index] = false;
      btn.addEventListener("click", () => handleDropdownClick(index));
    });

    setActiveDropdowns(newActiveDropdowns);

    return () => {
      Array.from(dropdownBtns).forEach((btn, index) => {
        btn.removeEventListener("click", () => handleDropdownClick(index));
      });
    };
  }, []);

  const handleDropdownClick = (index) => {
    setActiveDropdowns(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

const [activeDropdowns2, setActiveDropdowns2] = useState({});

  useEffect(() => {
    const dropdownBtns2 = document.getElementsByClassName("dropdown-btn2");
    const newActiveDropdowns2 = {};

    Array.from(dropdownBtns2).forEach((btn, index) => {
      newActiveDropdowns2[index] = false;
      btn.addEventListener("click", () => handleDropdownClick2(index));
    });

    setActiveDropdowns2(newActiveDropdowns2);

    return () => {
      Array.from(dropdownBtns2).forEach((btn, index) => {
        btn.removeEventListener("click", () => handleDropdownClick2(index));
      });
    };
  }, []);

  const handleDropdownClick2 = (index) => {
    setActiveDropdowns2(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <nav className = "nav-wrapper">
        <div id="mySidenav" className="sidenav" style={{ width: isOpen ? '320px' : '0' }}>
            <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>×</a>
            <div className="nav-contact">
              <div id="nav-phone">
                <FaPhone /> 
                <p>+1 (601) 981 8356</p>
              </div>
              <div id="nav-email">
                <IoMdMail /> 
                <p>bobbyslaughter@slaughtercomedy.com</p>
              </div>
            </div>
            <ul >
                <li>
                    <Link to='/'>Home</Link>
                </li>
                <div className="nav-dropdown">
                    <div class="dropdown-btn"> 
                        <p>About Me</p>
                        <IoIosArrowDown />
                    </div>
                    <div class="dropdown-container" style={{ display: activeDropdowns[0] ? 'block' : 'none' }}>
                        <Link to='/AboutBobby'>About Bobby</Link>
                        <Link to='/Testimonials'>Testimonials</Link>
                        <Link to='/BusinessLicenses'>Business Liscenses</Link>
                        <Link to='/Archives'>Archives</Link>
                    </div>
                </div>
                <li>
                    <Link to='/MSwSNS'>Making Sense with Sanchez N Slaughter</Link>
                </li>
                <div className="nav-dropdown2">
                    <div class="dropdown-btn2">
                        <p>Event</p>
                        <IoIosArrowDown />
                    </div>
                    <div class="dropdown-container2" style={{ display: activeDropdowns2[0] ? 'block' : 'none' }}>
                        <Link to='/PastEvents'>Past Events</Link>
                        <Link to='/UpcomingEvents'>Upcoming Events</Link>
                        <Link to='/CurrentEvents'>Current Events</Link>
                    </div>

                </div>
                <li>
                    <Link to='/ComedyLinks'>Las Vegas Local Comedy Links</Link>
                </li>
                <li>
                    <Link to='/Media'>Media</Link>
                </li>
                <li>
                    <Link to='/Resources'>Resources</Link>
                </li>
            </ul>
        </div>
        <header className="nav-closed-header">
            <Link to="/" id="closed-nav-logo"> <img src={logo}></img> </Link>
            <span id="closed-nav" style={{ fontSize: '30px', cursor: 'pointer' }} onClick={openNav}>☰</span>
        </header>
      
    </nav>

);
}


export default SCNavbar;

// className={ `nav-b${show ? '-open' : ''}`}

    // <ul>
    //     <li>
    //         <Link to='/'>Home</Link>
    //     </li>
    //     <div className="nav-dropdown">
    //         <div className="nav-dropdown-header">
    //             <p>About Me</p> <IoIosArrowDown className="dropdown-about-collapsed"/> <IoIosArrowUp className="dropdown-about-open"/>
    //         </div>
    //         <Link to='/AboutBobby'>About Bobby</Link>
    //         <Link to='/Testimonials'>Testimonials</Link>
    //         <Link to='/BusinessLicenses'>Business Liscenses</Link>
    //         <Link to='/Archives'>Archives</Link>
    //     </div>
    //     <li>
    //         <Link to='/MSwSNS'>Making Sense with Sanchez N Slaughter</Link>
    //     </li>
    //     <div className="nav-dropdown">
    //         <div className="nav-dropdown-header">
    //             <p>Events</p> <IoIosArrowDown className="dropdown-events-collapsed"/> <IoIosArrowUp className="dropdown-events-open"/>
    //         </div>
    //         <Link to='/PastEvents'>Past Events</Link>
    //         <Link to='/UpcomingEvents'>Upcoming Events</Link>
    //         <Link to='/CurrentEvents'>Current Events</Link>
    //     </div>
    //     <li>
    //         <Link to='/ComedyLinks'>Las Vegas Local Comedy Links</Link>
    //     </li>
    //     <li>
    //         <Link to='/Media'>Media</Link>
    //     </li>
    //     <li>
    //         <Link to='/Resources'>Resources</Link>
    //     </li>
    // </ul>