import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SCArchives.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';

import businessLicense3 from '../assets/photos/businesslic3.png';

import SCFooter from '../SCFooter';

function SCArchives() {

const [modalVisible, setModalVisible] = useState(false);
const [currentImage, setCurrentImage] = useState(null);
const [hidden, unsetHidden] = useState(true);

const toggle = () => {
unsetHidden(!hidden);
};

const openModal = (item, index) => {
setCurrentImage();
setModalVisible(true);
toggle(unsetHidden);
};

const closeModal = () => {
setModalVisible(false);
setCurrentImage(null);
};

return (
    <div className="BLarchive-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="BLarchive-hero">
            <div id="BLarchive-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div id="BLarchive-hero-overlay">
                <h1>Archives</h1>
            </div>
        </section>
        <section id="BLarchive-wrapper">
            <div className="BLarchive-photo">
                {<img src={businessLicense3} onClick={() => openModal(businessLicense3)}></img>

                }

                {modalVisible && (
                    <div className={hidden ? "modal-hidden" : "modal-show"}>
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img className="modal-content" src={businessLicense3}></img>
                    </div>
                )}

            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>
    );
}

export default SCArchives;