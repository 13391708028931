import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import './index.css';
import SlaughterComedy from './SlaughterComedy';
import SCAboutBobby from './AboutMe/SCAboutBobby';
import SCTestimonials from './Testimonials/SCTestimonials';
import SCBusinessLicenses from './BusinessLicenses/SCBusinessLicenses';
import SCArchives from './Archives/SCArchives';
import SCMakingSensewSNS from './MSwSNS/SCMakingSensewSNS';
import SCPastEvents from './Events/SCPastEvents';
import SCUpcomingEvents from './Events/SCUpcomingEvents';
import SCCurrentEvents from './Events/SCCurrentEvents';
import SCComedyLinks from './LVLCL/SCComedyLinks';
import SCMedia from './Media/SCMedia';
import SCResources from './Resources/SCResoures';





import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <SlaughterComedy />,
  },
  {
    path: "/AboutBobby",
    element: <SCAboutBobby />,
  },
  {
    path: "/Testimonials",
    element: <SCTestimonials />,
  },
  {
    path: "/BusinessLicenses",
    element: <SCBusinessLicenses />,
  },
  {
    path: "/Archives",
    element: <SCArchives />,
  },
  {
    path: "/MSwSNS",
    element: <SCMakingSensewSNS />,
  },
  {
    path: "/PastEvents",
    element: <SCPastEvents />,
  },
  {
    path: "/UpcomingEvents",
    element: <SCUpcomingEvents />,
  },
  {
    path: "/CurrentEvents",
    element: <SCCurrentEvents />,
  },
  {
    path: "/ComedyLinks",
    element: <SCComedyLinks />,
  },
  {
    path: "/Media",
    element: <SCMedia />,
  },
  {
    path: "/Resources",
    element: <SCResources />,
  },
  // {
  //   path: "/gallery",
  //   element: <Gallery />,
  // },
  // {
  //   path: "/about",
  //   element: <About />,
  // },
  // {
  //   path: "/contact",
  //   element: <Contact />,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}>
    <SlaughterComedy />
  </RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();