import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

import './SlaughterComedy.css';

import SCNavbar from './SCNavbar';
// import SCOpenNavbar from './SCOpenNav';

import SCLandingHero from './LandingHero/SCLandingHero';
import SCLandingAbout from './LandingAbout/SCLandingAbout';
import SCLandingIntermission from './LandingIntermission/SCLandingIntermission';
import SCLandingTestimonials from './LandingTestimonialRabbit/SCLandingTestimonial';
import SCLandingClients from './LandingClients/SCLandingClients';
import SCLandingEvents from './LandingEvents/SCLandingEvents';
import SCLandingServices from './LandingServices/SCLandingServices';
import SCLandingMedia from './LandingMedia/SCLandingMedia';
import SCContactForm from './ContactForm/SCContactForm';

import SCFooter from './SCFooter';


function SlaughterComedy() {



  return (
    <div className="landing-wrapper">

        <section>
          <SCNavbar/>
        </section>

      <section className="landing-main-container">

        <section id="hero" >
          <SCLandingHero />
        </section>
        <section>
          <SCLandingAbout />
        </section>
        <section>
          <SCLandingIntermission />
        </section>
        <section>
          <SCLandingTestimonials />
        </section>
        <section>
          <SCLandingClients />
        </section>
        <section>
          <SCLandingEvents />
        </section>
        <section>
          <SCLandingServices />
        </section>
        <section>
          <SCLandingMedia />
        </section>
        <section id="contact-form-hash">
          <SCContactForm />
        </section>
        <section>
          <SCFooter />
        </section>

      </section>
      
    </div>
  );
}

export default SlaughterComedy;
