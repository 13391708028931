import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './SCAboutBobby.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';
import profilephoto from '../assets/photos/profilephoto.jpg';

import ourMedia5 from '../assets/photos/bobbywithrecord.jpg';

import SCFooter from '../SCFooter';




function SCAboutBobby() {


  const [isVisible, setIsVisible] = useState(false);  
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

return (

    <div className="aboutbobby-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="about-hero">
            <div id="about-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div ref={elementRef} className={`my-component ${isVisible ? 'enter-animation' : ''}`} id="about-hero-overlay">
                <img src={profilephoto}></img>
                <h1>About Bobby</h1>
                <h2>Owner of Slaughter Comedy LLC</h2>
            </div>
        </section>
        <section id="read-about">
            <div className="about-text">
                <p className="about-p">
                    Slaughter Comedy LLC, owned and operated by Bobby Slaughter, a veteran of the U.S.
                    Air Force was established as a Limited Liability Company (NRS86) in November 2016.
                    Slaughter Comedy is a member of the Las Vegas Metro Chamber of Commerce, the Las Vegas Music Association (LVMA),
                    a 401K organization, and registered with the American Society of Composers, Authors and Publishers (ASCAP).
                    Slaughter Comedy promotes local businesses, local comedians, and entertainers through the production of comedy and entertainment venues in commercial areas all around Las Vegas.
                    Slaughter Comedy accomplishes its goals by producing affordable clean (but adult) comedy/variety shows at local venues.
                    Slaughter Comedy additionally produces and promotes shows through tasteful viral videos posted on social media networks such as Facebook and YouTube.
                </p>
                <p className="about-p">
                    Slaughter Comedy Productions’ goal is to provide a professional, competent interaction between the audience and the performers.
                    As comedian and Host, Bobby regularly performs in Las Vegas from the bright lights of The StLrip to the cozy confines of the Local’s favorite haunts to improve his standup.
                    As an industry professional Bobby works tirelessly to put on professional high-quality performances; working with an eclectic mix of entertainers (comedians, singers, rappers, spoken word, and others).
                    I support venues as small as 45- 75 seats and larger, to seated venues of 250-400 to provide a well-organized and smoothly choreographed reliable entertainment experience.
                    Slaughter Comedy also collaborates with other producers to develop and promote shows!
                </p>
            </div>
            <div id="about-text-img">
                <div className="about-text-img-section" id ="image-offset">
                    <img ref={elementRef} className={`my-component ${isVisible ? 'enter-animation1' : ''}`} id="about-img1" src={ourMedia5}></img>
                    <img ref={elementRef} className={`my-component ${isVisible ? 'enter-animation2' : ''}`} id="about-img2" src={profilephoto}></img>
                </div>
                <div className="about-text-img-section">
                    <p className="about-p">
                        His taste for the outrageous clearly established and his comedic influences firmly initiated,
                        Bobby kept his head down, did what he had to do, and developed a compliant and competent exterior that belied the witty irreverence that was aching to escape his lips.
                        With a career in the Air Force and a well-traveled life as fodder for the stage show that was always running behind the curtains in Bobby’s brain,
                        he would regularly pull aside the curtain for intimate gatherings of his friends, family, and co-workers bringing them to tears with laughter. Finally having belief that he had summoned the courage to perform for the first time,
                        young Mr. Slaughter found himself next up at an Atlanta comedy night when he was completely blown away by an up-and-coming young comedian.
                    </p>
                    <p className="about-p">
                        With each roar from the crowd for the shining star of the night,
                        Bobby shrunk further side-stage until he turned and walked away.
                        He had lost his will, his courage to follow such a polished act.
                        Yet it was this very bitter defeat that drove Bobby to study, work, write,
                        and grind to become the pro he is today. You see Bobby got back on that stage,
                        brought his game and something amazing happened: The audience laughed. And Laughed.
                        Some even fell out of their chairs. In fact, they’re still laughing every time Bobby Slaughter takes the stage. And are we all grateful he did.
                        If you haven’t been Slaughtered by comedy then you need to book this Unique Performer.
                    </p>
                </div>
            </div>
            <div className="about-text">
                <p className="about-p">
                    Working through my company, Slaughter Comedy, I work to create the right event,
                    tailored to your unique venue (space) and budget by arranging shows (sound, lighting, staging, design, posters,
                    flyers and décor) for the event; this includes booking comedians and entertainers.
                    I have worked with some of the best local performers in Las Vegas and for less than the price of a television advertisement,
                    Slaughter Comedy will create and produce an event that will increase foot traffic and revenue for your company.
                    Slaughter Comedy is a member of the Las Vegas Metro Chamber of Commerce and the Las Vegas Music Association (401C) which supports charitable causes.
                </p>
            </div>
        </section>
        <section>
            <SCFooter />
        </section>

    </div>

);
}

export default SCAboutBobby;