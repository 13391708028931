import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SCBusinessLicenses.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';

import businessLicense1 from '../assets/photos/businesslic1.jpg';
import businessLicense2 from '../assets/photos/businesslic2.jpg';

import SCFooter from '../SCFooter';

function SCBusinessLicenses() {

const [modalVisible, setModalVisible] = useState(false);
const [currentImage, setCurrentImage] = useState(null);
const [hidden, unsetHidden] = useState(true);

const toggle = () => {
unsetHidden(!hidden);
};

const openModal = (item, index) => {
setCurrentImage();
setModalVisible(true);
toggle(unsetHidden);
};

const closeModal = () => {
setModalVisible(false);
setCurrentImage(null);
};

const [modalVisible2, setModalVisible2] = useState(false);
const [currentImage2, setCurrentImage2] = useState(null);
const [hidden2, unsetHidden2] = useState(true);

const toggle2 = () => {
unsetHidden2(!hidden2);
};

const openModal2 = (item, index) => {
setCurrentImage2();
setModalVisible2(true);
toggle2(unsetHidden2);
};

const closeModal2 = () => {
setModalVisible2(false);
setCurrentImage2(null);
};

return (
    <div className="businesslicenses-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="businesslicense-hero">
            <div id="businesslicense-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div id="businesslicense-hero-overlay">
                <h1>Business Licenses</h1>
            </div>
        </section>
        <section id="license-wrapper">
            <div className="businesslicense-photo">
                {<img src={businessLicense1} onClick={() => openModal(businessLicense1)}></img> 
                
                }

                {modalVisible && (
                    <div className={hidden ? "modal-hidden" : "modal-show"}>
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img className="modal-content" src={businessLicense1}></img>
                    </div>
                )}
            </div>
            <div className="businesslicense-photo">
                {<img src={businessLicense2} onClick={() => openModal2(businessLicense2)}></img>
                
                }

                {modalVisible2 && (
                    <div className={hidden2 ? "modal-hidden" : "modal-show"}>
                        <span className="close" onClick={closeModal2}>&times;</span>
                        <img className="modal-content" src={businessLicense2}></img>
                    </div>
                )}
            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>
    );
}

export default SCBusinessLicenses;