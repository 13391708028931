import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './SCUpcomingEvents.css';
import SCNavbar from '../SCNavbar';

import hero from '../assets/photos/hero.jpg';

import OMG from '../assets/photos/OMGpizza.jpg';

import SCS from '../assets/photos/smokincomedyshow.jpg';

import SNLOM from '../assets/photos/sundaynightlive.jpg';

import SCFooter from '../SCFooter';

function SCUpcomingEvents() {

return (

    <div className="upcomingevents-wrapper">
        <section>
            <SCNavbar />
        </section>
        <section id="upcomingevents-hero">
            <div id="upcomingevents-hero-backdrop">
                {/* <img src={hero}></img> */}
            </div>
            <div id="upcomingevents-hero-overlay">
                <h1>Upcoming Events</h1>
            </div>
        </section>
        <section className="upcomingevents-section">
            <div>
                <h3>OMG Pizza Show</h3>
                <img src={OMG}></img>
            </div>
            <div>
                <h3>Las Vegas Smokin' Comedy Showcase</h3>
                <img src={SCS}></img>
            </div>
            <div>
                <h3>Sunday Night Live Open Mic & Showcase</h3>
                <img src={SNLOM}></img>
            </div>
        </section>
        <section>
            <SCFooter />
        </section>
    </div>

);
}

export default SCUpcomingEvents;